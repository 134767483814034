import React, { ReactNode, useContext, useEffect, useState } from "react";
import { BotEditor } from "./BotEditor";
import { StandardDrawer, useStandardDrawer } from "services/hooks/useDrawer";
import StandardButton from "components/common/StandardButton";
import useSelectorWithParams from "services/hooks/useSelectorWithParams";
import {
  AddBot,
  BotProps,
  BotSelector,
  BotsSlice,
  GetBot,
  TempBotSelector,
  UpdateBot,
} from "../containers/BotsSlice";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import FormattedMessage from "components/common/FormattedMessage";
import ButtonWithIcon from "modules/smart/components/list-item/base-list-item/ButtonWithIcon";
import { PlusIcon, UpdateIcon } from "modules/panel/components/ft-icons";
import { DeleteBotBtn } from "./DeleteBotBtn";
import useRelaxData from "tools/relaxForm/useRelaxData";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faTrashCircle } from "@fortawesome/pro-light-svg-icons";
import _ from "lodash";
import { useDispatch } from "react-redux";
import RelaxMultiform from "tools/relaxForm/RelaxMultiform";
import { Spin } from "antd";

export type ContextProps = {
  bot?: BotProps;
};

export type BotContextProps = {
  botId?: number;
  actionType: "create" | "update";
  postId?: string;
  btnContent?: ReactNode;
  setKey?: (data: any) => void
};

const BotContext = React.createContext({} as ContextProps);
BotContext.displayName = "Bot Context";
export const useBot = (): ContextProps => useContext(BotContext);

const BotContextProvider = React.forwardRef((props: BotContextProps) => {
  const getBot = useAPI({});
  const addBot = useAPI(AddBot);
  const botFromStore: BotProps = useSelectorWithParams([
    BotSelector,
    props.botId,
  ]);
  const temBotInStore = useSelectorWithParams(TempBotSelector);
  const rd = useRelaxData({});
  const [drawerRef, drawerOpen, drawerClose] = useStandardDrawer();
  const dispatch = useDispatch();

  const bot = props.actionType === "create" ? temBotInStore : botFromStore;

  const [botBasedOn, setBotBasedOn] = useState(bot?.keyword != null ? "keyword" : "all-comments");
  const [integration, setIntegration] = useState<string>("private_replies");


  useEffect(() => {
    if (bot?.integrations !== undefined && bot?.integrations.length > 0) {
      if (bot?.integrations[0]?.projectIntegration?.type === "mailer-lite") {
        setIntegration("mailer-lite");
      } else if (
        bot?.integrations[0]?.sms_api_group_id ||
        bot?.integrations[0]?.projectIntegration?.type === "sms-api"
      ) {
        setIntegration("sms-api");
      } else if (
        bot?.integrations[0]?.subscribers_list_hash ||
        bot?.integrations[0]?.projectIntegration?.type === "fresh-mail"
      ) {
        setIntegration("fresh-mail");
      }
    }

    const clonBot = _.cloneDeep(bot);
    if (bot) {
      rd.setCurrentValue(clonBot);
    }
  }, [bot]);

  const handleCreateBot = () => {
    addBot
      .call({
        body: props.postId
          ? { ...rd.currentValue, page_post_id: props.postId }
          : { ...rd.currentValue },
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          getBot.call(GetBot(res.data.id)).then((res: any) => {
            if (res.status === 200) {
              drawerClose();
              if(props.setKey) props.setKey((prevKey:any) => prevKey + 1)
            }
          });
        }
      });
  };

  const addOrUpdateBot = (data: any, temBotInStore: any) => {
    const currentValue = rd.currentValue;

    if (data === null) return null;
    if (data?.confirmations) {
      const message = data.confirmations[0].message
        ? data.confirmations[0].message
        : currentValue?.confirmations?.[0]?.message;
      const keyword = data.confirmations[0].keyword
        ? data.confirmations[0].keyword
        : currentValue?.confirmations?.[0]?.keyword;
      const error_message = data.confirmations[0].error_message
        ? data.confirmations[0].error_message
        : currentValue?.confirmations?.[0]?.error;
      data = {
        ...data,
        confirmations: [{ error_message, message, keyword, order: 0 }],
      };
    }
    if (data?.integration_group_external_id) {
      data = {
        integration_group_external_id: data.integration_group_external_id.toString(),
        type: integration,
      };
    }

    if (data.message) {
      data = { ...data, keyword: currentValue?.keyword };
    }

    if (data?.integrations) {
      const value = data.integrations[0];
      data = {
        ...data,
        type: integration,
        integration_group_external_id:
          value.integration_group_external_id.toString() ||
          value.subscribers_list_hash ||
          value.sms_api_group_id,
      };
    }

    const updatedValue = {
      ...currentValue,
      ...data,
      activation: botBasedOn,
    };

    if (props.actionType == "create") {
      let tempBotUpdate;
      if (temBotInStore) {
        tempBotUpdate = {
          ...temBotInStore,
          ...updatedValue,
        };
      } else {
        tempBotUpdate = {
          id: 0,
          ...updatedValue,
          temp: true,
        };
      }

      dispatch(BotsSlice.actions.addTempBot({ data: tempBotUpdate }));
    }

    rd.setCurrentValue(updatedValue);
    const finalValue = removeEmptyParamsFromBot(updatedValue)
    return finalValue;
  };

  const removeEmptyParamsFromBot = (bot: any): any => {
    return Object.fromEntries(
      Object.entries(bot)
        .filter(([_, value]) => 
          value !== null && value !== "" && !(Array.isArray(value) && value.length === 0)
        )
        .map(([key, value]) =>
          [key, typeof value === "object" && !Array.isArray(value) ? removeEmptyParamsFromBot(value) : value]
        )
    );
  };

  const openDrawerBtn =
    props.btnContent === undefined ? (
      <ButtonWithIcon
        icon={props.actionType === "create" ? <PlusIcon /> : <UpdateIcon />}
        onClick={() => {
          drawerOpen();
        }}
      >
        {props.actionType === "create" ? (
          <FormattedMessage
            id="bot.components.createBot"
            defaultMessage="Create bot"
          />
        ) : (
          <FormattedMessage
            id="bot.components.createBot.heading"
            defaultMessage="Edit Message Bot"
          />
        )}
      </ButtonWithIcon>
    ) : (
      <StandardButton
        onClick={() => {
          drawerOpen();
        }}
        style={{ marginBottom: "7px" }}
      >
        {props.btnContent}
      </StandardButton>
    );

  return (
    <BotContext.Provider
      value={{
        bot: bot,
      }}
    >
      <StandardDrawer
        ref={drawerRef}
        width={"1300px"}
        layout="standard"
        mask={true}
        title={
          <>
            {props.actionType === "create" ? (
              <FormattedMessage
                id="bot.components.createBot"
                defaultMessage="Add Message Bot"
              />
            ) : (
              <FormattedMessage
                id="bot.components.createBot.heading"
                defaultMessage="Edit Message Bot"
              />
            )}
          </>
        }
        zIndex={1000}
        onClose={drawerClose}
        footerWidth={"95%"}
        footer={
          <>
            {props.actionType === "create" ? (
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <StandardButton
                  onClick={handleCreateBot}
                  type={"primary"}
                  loading={addBot.loading}
                >
                  <FormattedMessage
                    id="modules.bot.form.addBot"
                    defaultMessage="Add bot"
                  />
                </StandardButton>
              </div>
            ) : (
              <div>
                <StandardButton>
                  <DeleteBotBtn botId={props.botId} close={drawerClose}>
                    <AwesomeIcon icon={faTrashCircle} size="1x" />
                  </DeleteBotBtn>
                </StandardButton>
              </div>
            )}
          </>
        }
      >
        <RelaxMultiform
          relaxData={rd}
          callAPI={
            props.actionType === "update" ? UpdateBot(props.botId) : undefined
          }
          initialValues={bot}
          finalizeSubmit={(data: any) => {
            return addOrUpdateBot(data, bot);
          }}
        >
          <Spin spinning={addBot.loading} size="large">
            <BotEditor
              actionType={props.actionType}
              botBasedOn={botBasedOn}
              setBotBasedOn={setBotBasedOn}
              integration={integration}
              setIntegration={setIntegration}
            />
            {props.actionType === "create" && (
              <APIErrorDisplay error={addBot.error} />
            )}
          </Spin>
        </RelaxMultiform>
      </StandardDrawer>

      {openDrawerBtn}
    </BotContext.Provider>
  );
});

export default BotContextProvider;
