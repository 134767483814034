import { InputNumber } from "antd";
import AwesomeIcon from "components/common/AwesomeIcon";
import StandardButton from "components/common/StandardButton";
import { useMemo } from "react";
import useSimpleBreakpoint from "services/hooks/useSimpleBreakpoint";
import { faCity, faFlag, faMap, faTrash } from "@fortawesome/pro-light-svg-icons";

const typeIcons:Record<string, any> = ({
    "country": faFlag,
    "administrative_area_level_1": faMap,
    "locality": faCity
})

export default function GeoPointsEntryGoogle (props: any) {

    const [bp, bpi] = useSimpleBreakpoint();

    if (!props.point) return (
        <div className="geo-points-entry">
            Invalid Point
        </div>
    )

    const type = props?.point?.data?.types?.[0];

    const handleRadiusChange = (value: number | null) => {
        if (props.onChange) props.onChange({...props.point, radius: value});
    }


    return (
        <div className="geo-points-entry">
            <div className="geo-points-entry-type">
                <AwesomeIcon icon={typeIcons[type]} size="xl"/>
            </div>
            <div className="geo-points-entry-location">{props.point.data.formatted_address}</div>
            <div className="geo-points-entry-range">
                {type === "country" ? "Whole country": 
                <InputNumber
                    min={20}
                    addonAfter={bpi >=3 ? "km" : null}
                    value={props.point?.radius}
                    onChange={handleRadiusChange}
                />
            }   
            </div>
            <div className="geo-points-entry-remove">
                <StandardButton icon = {faTrash} shape="circle" onClick={() => props.onRemove && props.onRemove(props.point)}/>
            </div>
        </div>
    )
}