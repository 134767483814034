import React, { FunctionComponent } from "react";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import FormattedMessage from "components/common/FormattedMessage";
import PanelTopBarItem from "providers/routing/layouts/templates/panel/top-bar/top-bar-item";
import { ArrowIcon } from "modules/panel/components/ft-icons";

import {
  COMPANY_PROJECTS,
  getUrl,
  LOGOUT,
  PRODUCTS,
  STATEMENT_MARKETING_CONSENTS,
  STATEMENT_TERMS_OF_SERVCIE,
  TASKS,
} from "modules/panel/config/routes";
import AwesomeIcon from "components/common/AwesomeIcon";
import { faBriefcase, faFileSignature, faFolder, faListCheck, faRectangleAd, faRectangleList, faRightFromBracket, faStore, faUserSecret } from "@fortawesome/pro-light-svg-icons";

type UserProps = {
  name: string;
  companyName: string;
  avatar: string;
};

const User: FunctionComponent<UserProps> = ({ name, companyName, avatar }) => (
  <Dropdown
    overlayClassName="top-bar-item-dropdown"
    trigger={["hover", "click"]}
    overlay={
      <Menu className="user-menu">
        <Menu.Item>
          <Link
            data-testid="companySettingsLink"
            className="company-settings"
            to="/panel/companySettings/invoiceDetails"
          >
            <AwesomeIcon icon={faBriefcase} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.companySettings"
              defaultMessage="Company"
            />
          </Link>
        </Menu.Item>
        {/* <Menu.Item>
          <Link
            data-testid="companySubscriptionsLink"
            className="company-subscriptions"
            //to={getUrl(SUBSCRIPTIONS)}
            to="/panel/companySettings/subscriptions"
          >
            <AwesomeIcon icon={faRectangleList} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.companySubscriptions"
              defaultMessage="Subscriptions"
            />
          </Link>
        </Menu.Item> */}
        <Menu.Item>
          <Link
            data-testid="projectsLink"
            className="company-projects"
            to={getUrl(COMPANY_PROJECTS)}
          >
            <AwesomeIcon icon={faFolder} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.projects"
              defaultMessage="Projects"
            />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            data-testid="productsLink"
            className="products"
            to={getUrl(PRODUCTS)}
          >
            <AwesomeIcon icon={faStore} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.products"
              defaultMessage="Products"
            />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            data-testid="tasks"
            className="tasks"
            to={getUrl(TASKS)}
          >
            <AwesomeIcon icon={faListCheck} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.tasks"
              defaultMessage="Tasks"
            />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            data-testid="termsOfService"
            to={getUrl(STATEMENT_TERMS_OF_SERVCIE)}
          >
            <AwesomeIcon icon={faFileSignature} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.termsOfService"
              defaultMessage="Terms of Service"
            />
          </Link>
        </Menu.Item>

        <Menu.Item>
          <a
            data-testid="privacyPolicy"
            href={import.meta.env.VITE_INSTANCE_PRIVACY_POLICY_URL}
          >
            <AwesomeIcon icon={faUserSecret} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </a>
        </Menu.Item>

        <Menu.Item>
          <Link
            data-testid="marketingConsents"
            to={getUrl(STATEMENT_MARKETING_CONSENTS)}
          >
            <AwesomeIcon icon={faRectangleAd} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.marketingConsents"
              defaultMessage="Marketing Consents"
            />
          </Link>
        </Menu.Item>  

        <Menu.Item>
          <Link data-testid="logoutLink" className="logout" to={getUrl(LOGOUT)}>
            <AwesomeIcon icon={faRightFromBracket} style={{paddingRight: "5px"}}/>
            <FormattedMessage
              id="panel.providers.routing.templates.panel.userDropdown.logout"
              defaultMessage="Logout"
            />
          </Link>
        </Menu.Item>
      </Menu>
    }
    className="user"
  >
    <div className="top-bar-item-dropdown-wrapper">
      <PanelTopBarItem
        title={name}
        underline={companyName}
        image={avatar}
        className="user"
        icon={<ArrowIcon />}
      />
    </div>
  </Dropdown>
);

export default User;
