import { Button, Input, InputNumber, Select } from "antd";
import Map from "components/map/Map";
import "./geo-points.less";
import useAPI, { callAPIProps } from "services/hooks/useAPI";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDebounceTrigger } from "services/hooks/useDebounce";
import { useGoogleGeocoder } from "services/hooks/useGoogleGeocoder";
import GeoPointsEntryGoogle from "./GeoPointEntryGoogle";
import _ from "lodash";

type GeoPoint = {
    id: string, 
    type: "city" | "region" | "country",
    name: string,
    region?: string,
    country_name: string,
  }
  

const adjustPoint = (point:any) => {
    return {
        address: point.formatted_address,
        data: {
            address_components: point.address_components,
            formatted_address: point.formatted_address,
            geometry: point.geometry,
            place_id: point.place_id,
            types: point.types,
        },
        latitude: point.geometry.location.lat(),
        longitude: point.geometry.location.lng(),
        radius: point.radius || 20,
    }
     
  }
  
  const isPointInPoint = (point:any, pointToCheck:any) => {
    if (!point || !pointToCheck) return false;
    const pointType = point.data.attributes.type;
    const ptcType = pointToCheck.data.attributes.type;
  
    if (pointType === "country" && ptcType === "country") return false;
    if (ptcType === "country") {
      return point.data.attributes.country_name === pointToCheck.data?.attributes.name;
    }
    if (ptcType === "region") {
      return point.data.attributes.region === pointToCheck.data.attributes.name && point.data.attributes.country_name === pointToCheck.data.attributes.country_name;
    }
    if (ptcType === "city") {
      return false;
    }
  }
  
  const isPointInPointFromGoogleQuery = (point:any, pointToCheck:any) => {
    if (!point || !pointToCheck) return false;

    const pointType = point.address_components[0].types[0];
    const ptcType = pointToCheck.data.address_components[0].types[0];

    
   // console.log("is", point.formatted_address, "in", pointToCheck.data.formatted_address)
   // console.log("Type: is", pointType, "in", ptcType)

    // const ptcType = pointToCheck?.data?.attributes.type;
  
    // if (pointType === "country" && ptcType === "country") return false;
    // if (ptcType === "country") {
    //   //return point.country_name === pointToCheck.name;
    //   return point.country_name === pointToCheck.data?.attributes?.country_name;
    // }
    // if (ptcType === "region") {
    //   return point.region === pointToCheck.data?.attributes?.name && point.country_name === pointToCheck.data?.attributes?.country_name;
    // }
    // if (ptcType === "city") {
    //   return false;
    // }
  }
  
  const isPointInAnyRegion = (point:GeoPoint, regions:GeoPoint[]) => {
    return regions ? regions.some((region) => isPointInPointFromGoogleQuery(point, region)) : false
  }


export type GeoPointsInputProps = {
    onChange?: (geoPoints: any) => void;
    onBlur?: () => void;
    value?: any[]
}

const filterPointsAfterAdding = (point:any, values:any) => {


            const type = point?.data?.attributes?.type;
            let filtered:any[] = []

            if (type === "country" || type === "region") {
              filtered =  values.filter((value:any) => !isPointInPoint(value, point));
            } else {
              filtered = values;
            }

            return [...filtered, point];
}

const renderSuggestion = (suggestion:any, values:any) => {


    const alreadyOnTheList = values && values.some((value:any) => suggestion.place_id === value.data.place_id);
    const isInRegion = isPointInAnyRegion(suggestion, values);

    return ({
        label: suggestion.formatted_address,
        value: suggestion.place_id,
        disabled: alreadyOnTheList,
    })


    //const isAlreadyAdded = values.some((value:any) => suggestion.key === value.data.attributes.key);
    // return { 
    //   label: getLabel(suggestion),
    //   value: suggestion.id,
    //   disabled: isInRegion || isAlreadyAdded,
    // }
}

export default function GeoPointsInputGoogle(props: GeoPointsInputProps) {

    const {search, results, clear} = useGoogleGeocoder({});

    const [debounceAsk] = useDebounceTrigger(search, 500)

    const safeHandleChange = (value: any) => {

        if (_.isEqual(value, props.value)) return;
        props.onChange && props.onChange(value)
        props.onBlur && props.onBlur();

    }

    const handleNewPointSelected = (point: any) => {

        if (!point) return;

        const newPoint = adjustPoint(results.find((r:any) => r.place_id === point));

        const filtered = filterPointsAfterAdding(newPoint, props.value || [])


        safeHandleChange(filtered);
        clear();

    }
    
    const removePoint = (point: any) => {
        if (!props.value) return;
        const newPoints = props.value.filter((p: any) => p.data.place_id !== point.data.place_id);
        safeHandleChange(newPoints);
    }

    const handlePointChange = (point: any) => {
        if (!props.value) return;
        const newPoints = props.value.map((p: any) => p.data.place_id === point.data.place_id ? point : p);
        safeHandleChange(newPoints);
    }


    const mappedPoints = useMemo(() => {
        if (!props.value) return [];
        
        return (props.value || []).map((point: any) => 
        <GeoPointsEntryGoogle 
        point={point} 
        onRemove={removePoint}
        onChange={handlePointChange}
        />);
    }, [props.value])

    const mappedCircles = useMemo(() => {
        if (!props.value) return [];

        return (props.value)
        .filter(point => point?.data.types[0] !== "country")
        .map((point: any) => {
            if (!point.longitude || !point.latitude) return null;
            return {
                position: {
                    lat: point.latitude,
                    lng: point.longitude
                },
                shape: "MarkerShapeCircle",
                radius: point.radius*1000
            }
        }).filter((p: any) => p !== null);

    }, [props.value])

    
    const mappedMarkers = useMemo(() => {
        if (!props.value) return [];

        return (props.value)
        .filter(point => point?.data.types[0] !== "country")
        .map((point: any) => {
            if (!point.longitude || !point.latitude) return null;
            return {
                position: {
                    lat: point.latitude,
                    lng: point.longitude
                },
            }
        }).filter((p: any) => p !== null);

    }, [props.value])

    const mappedRegions = useMemo(() => {
        if (!props.value) return [];
        return props.value
        .filter(point => point?.data.types[0] !== "country")
        .map(r => {
          return r.country
        })
    }, [props.value])

    return (
        <div className="new-geo-points">
            <div className="input-container">
                <Select 
                    filterOption={false}
                    showSearch
                    onSearch={debounceAsk}
                    onSelect={handleNewPointSelected}
                    options={results && results.map((suggestion:any) => renderSuggestion(suggestion, props.value))}
                    autoClearSearchValue={true}
                    style={{
                        width: "100%"
                    }}
                />

            </div>
            <div className="geo-points-list">
                {mappedPoints}
            </div>
            <div className="standard-border map-container">
            <Map
                height={300}
                circles={mappedCircles}
                markers={mappedMarkers}
                regions={mappedRegions}
                scrollwheel={true}
            />
            </div>
        </div>
    )
}

