import { Col, Input, InputNumber, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import Checkbox from "components/inputs/Checkbox";
import URLInput from "components/inputs/URLInput";
import React, { ReactElement, ReactNode } from "react";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import {
  AddProduct,
  CatalogProductProps,
  DeleteProduct,
  UpdateProduct,
} from "state/productsCatalogSlice";
import RelaxField from "tools/relaxForm/RelaxField";
import RelaxFormWrapper from "tools/relaxForm/RelaxForm";
import useRelaxData from "tools/relaxForm/useRelaxData";
import { productColumns } from "./ProductsConfig";

type Props = {
  action: "add" | "edit" | "delete";
  productCatalogId?: number;
  label: ReactElement;
  product?: CatalogProductProps;
  catalogId: string;
};

export default function ProductModal(props: Props) {
  const [ref, open, close] = useStandardModal();
  const rd = useRelaxData();

  const addProduct = useAPI(AddProduct(props.catalogId));
  const updateProduct = useAPI(
    UpdateProduct(props.catalogId, props?.product?.id)
  );
  const deleteProduct = useAPI(
    DeleteProduct(props.catalogId, props?.product?.id)
  );

  const modalContent = () => {
    switch (props.action) {
      case "add":
      case "edit":
        return productColumns(rd, props?.product);
      case "delete":
        return (
          <>
            <FormattedMessage
              id="panel.productInCatalog.product.modal.delete.confirmation"
              defaultMessage="Are you sure you want to delete this product from the catalog?"
            />
          </>
        );
      default:
        return null;
    }
  };

  const handleAction = async () => {
    let res;
    try {
      switch (props.action) {
        case "delete":
          res = await deleteProduct.call();
          break;
        case "edit":
          res = await updateProduct.call({
            body: {
              ...rd.currentValue,
              item_group_id:"1"
            },
          });
          break;
        case "add":
          res = await addProduct.call({
            body: {
              ...rd.currentValue,
              item_group_id:"1"
            },
          });
          break;
        default:
          return false;
      }

      if (res.status === 200 || res.status === 204) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <StandardButton
        style={{ width: "100%" }}
        size="small"
        type={props.action === "delete" ? "danger" : "primary"}
        onClick={open}
      >
        {props.label}
      </StandardButton>
      <StandardModal
        ref={ref}
        onCancel={close}
        modalProps={{
          width: props.action === "delete" ? 600 : 1200,
        }}
        title={props.label}
        onOk={async () => {
          const result = await handleAction();

          if (result) {
            close();
          }
        }}
        loading={
          deleteProduct.loading || updateProduct.loading || addProduct.loading
        }
      >
        <>
          {modalContent()}
          <APIErrorDisplay
            error={
              deleteProduct.error || updateProduct.error || addProduct.error
            }
          />
        </>
      </StandardModal>
    </>
  );
}
