import { faFileImport } from "@fortawesome/pro-light-svg-icons";
import FormattedMessage from "components/common/FormattedMessage";
import StandardButton from "components/common/StandardButton";
import { useState } from "react";
import useAPI, { APIErrorDisplay } from "services/hooks/useAPI";
import { StandardModal, useStandardModal } from "services/hooks/useModal";
import Papa from "papaparse";
import { GetProductsCatalog, UpdateCatalog } from "state/productsCatalogSlice";

type Props = {
  catalogId: string;
};

export default function UploadProductsModal(props: Props) {
  const [ref, open, close] = useStandardModal();
  const uploadCsv = useAPI(UpdateCatalog(props.catalogId));
  const getProducts = useAPI(GetProductsCatalog(props.catalogId))
  const [tempFile, setTempFile] = useState([]);

  const parseCSV = (file: any) => {
    Papa.parse(file, {
      complete: (result: any) => {
        setTempFile(result.data);
      },
      header: true,
    });
  };

  const handleUploadFile = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      parseCSV(file);
    }
  };

  const handleUpdateCatalog = () => {
    const parsetTempTileItems =
      tempFile &&
      tempFile.filter((item) => {
        return Object.values(item).some(
          (value) => value !== "" && value !== null && value !== undefined
        );
      });

    uploadCsv
      .call({
        body: {
          items: parsetTempTileItems,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          close();
          getProducts.call()
        }
      });
  };

  return (
    <>
      <StandardButton icon={faFileImport} size="xl" onClick={open} />
      <StandardModal
        ref={ref}
        width={"900px"}
        onCancel={close}
        title={
          <FormattedMessage
            id="panel.productCatalogs.upload.csv.title"
            defaultMessage="Upload products categories"
          />
        }
        onOk={handleUpdateCatalog}
        loading={uploadCsv.loading}
      >
        <div>
          <FormattedMessage
            id="panel.productCatalogs.upload.csv.description"
            defaultMessage="You can upload a CSV file to add products categories."
          />
          <input type="file" accept=".csv" onChange={handleUploadFile} />
          {tempFile.length !== 0 && (
            <div>
              <FormattedMessage
                id="panel.productCatalogs.upload.csv.success"
                defaultMessage="File uploaded correctly"
              />
            </div>
          )}
          <div>
            <APIErrorDisplay error={uploadCsv.error} />
          </div>
        </div>
      </StandardModal>
    </>
  );
}
