
export const events = [
    {
        "id": "1",
        "name": "Przestań tracić czas na ręczne reklamy – AI to przyszłość!",
        "date": "11.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Czy nadal klikasz 'Promuj post' albo żonglujesz Ads Managerem, zamiast zająć się klientami? Dowiedz się, jak automatyzacja obniża koszty, sprawdź kalkulator oszczędności, poznaj skuteczne targetowanie i budowanie lejka sprzedażowego TOFU-MOFU-BOFU. Odkryj tajemnicę strategii 421 – Twój przepis na sukces! Case study: salony piękności, kosmetologia, paznokcie.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/4.png"
    },
    {
        "id": "2",
        "name": "Pisanie postów to już przeszłość – AI pisze za Ciebie!",
        "date": "12.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "CHAT GTP, Cloud, Gemini, LLAMA 3 – które narzędzie AI jest dla Ciebie? Naucz się schematów generujących treści w kilka sekund. Najlepsze narzędzia? Są dostępne w FastTony AI i to bez dopłat. Czas na rekord: 30 postów w mniej niż minutę!",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/5.png"
    },
    {
        "id": "3",
        "name": "Człowiek vs. Maszyna – kto lepiej tworzy reklamy na Facebooku?",
        "date": "13.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Promuj post, reklama w Ads Manager czy FastTony AI? Co działa lepiej? Co wymaga mniejszego wysiłku? Zobacz dlaczego marketerzy i agencje reklamowe przestały robić reklamy przez Ads Manager i wykorzystują do tego FastTony AI. Sprawdź sam, co jest lepsze dla Twojego biznesu. Case study: przykłady klientów FastTony.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/6.png"
    },
    {
        "id": "4",
        "name": "Jak zwiększyć ilość klientów, korzystając z pomocy AI?",
        "date": "14.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Poznaj sprawdzone sposoby wykorzystania sztucznej inteligencji w skutecznym pozyskiwaniu klientów online. Agenda: 1. Dlaczego reklamy to świetny sposób na pozyskanie klientów? 2. Jak AI może pomóc w pozyskiwaniu klientów? 3. Jak stworzyć reklamy za pomocą AI. 4. Automatyzacja procesów obsługi klienta zainteresowanego ofertą 5. Jak zablokować wystawianie negatywnych opinii i zdobywać tylko 5 gwiazdek. 6. Jak automatyzacja pozyskiwania i obsługi klientów pozwoli ci zarobić 30% więcej w 2025 roku.",
        "link": "https://akademia.fasttony.com/w/internet",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/7.png"
    },
    {
        "id": "5",
        "name": "Jak sprzedawać kursy ONLINE korzystając z pomocy AI?",
        "date": "15.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Poznaj sprawdzone sposoby wykorzystania AI w skutecznej sprzedaży kursów online. Dowiesz się: 1. Dlaczego reklamy to świetny sposób na sprzedaż kursów? 2. Jak AI może pomóc w pozyskiwaniu klientów? 3. Tworzenie reklam za pomocą AI Jak AI samo tworzy reklamy i targetuje w nich właściwe osoby optymalizując koszty 4. Jak Integracja z Web To Learn pomoże ci zautomatyzować pozyskiwanie klientów? 5. Jak zastosowanie TOFU, BOFU i MOFU buduje ci sprzedaż? 6. Dla Wszystkich którzy dotrwają do końca prezent Kurs Budowania Marki osobistej za Darmo",
        "link": "https://akademia.fasttony.com/w/kursy",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/8.png"
    },
    {
        "id": "6",
        "name": "Google Ads i Forsant.io – prosta konfiguracja, większe zyski",
        "date": "16.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Skonfigutowanie konta i reklam w Google ads w mniej niż 10 minut? TAK! Dzięki Forsant konfiguracja i późniejsza optymalizacja nie musi być skomplikowana. Zobacz, jak łatwo to zrobić z Forsant.IO, porównując z klasycznym Google Ads. Plus: instruktaż wideo krok po kroku!",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/9.png"
    },
    {
        "id": "7",
        "name": "Jak zdobywać pozycjonujące komentarze w Google za darmo.",
        "date": "17.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Dowiedz się, jak blokować negatywne komentarze od sfrustrowanych klientów i zbierać tylko pozytywne opinie. Zobacz, jak AI pomaga zwiększyć skuteczność w utrzymaniu klienta oraz tworzyć automatycznie reklamy do osób, które dzwoniły do Twojej firmy oraz do ich znajomych i poszukujących podobnych usług.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/Naglowek-3000-x-2000-px-6000-x-4000-px-700-x-467-px-3.png"
    },
    {
        "id": "8",
        "name": "Sztuka przyciągania – uwodzenie klientów za pomocą AI",
        "date": "18.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Czy wiesz, czego naprawdę pragną Twoi klienci? Z AI możesz wzbudzać zainteresowanie, motywować do działania i sprawić, by nikt się nie wycofał. Daj się przekonać, że zdobywanie klientów może być prostsze niż myślisz dzięki AI! W trakcie tego materiału dowiesz się co mówić aby nigdy ci nie odmawiano.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/11.png"
    },
    {
        "id": "9",
        "name": "Produkt Feed – sekret sukcesu sklepów w rękach lokalnych firm",
        "date": "19.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Dowiedz się, jak FastTony planuje przejąć najskuteczniejsze kampanie e-commerce i dostosować je do potrzeb lokalnych firm. Mówimy stop na niesprawiedliwe traktowanie lokalnych firm i dajemy technologię zarezerwowaną tylko dla sklepów każdemu lokalnemu przedsiębiorcy. Case study: salon beauty – wzrost rejestracji o 388% dzięki local feed!",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/12.png"
    },
    {
        "id": "10",
        "name": "Sztuka targetowania – jak osiągnąć najlepsze wyniki?",
        "date": "20.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Czym są zainteresowania, zachowania i grupy niestandardowe? Jak z nich korzystać, aby dotrzeć do właściwych osób? Odkryj, jak FastTony AI wykorzystuje te funkcje dla Twojej firmy.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/13.png"
    },
    {
        "id": "11",
        "name": "Jak generować leady? Głupie Pytanie: TANIO i DUŻO! Sztuką jest wiedzieć JAK to zrobić!",
        "date": "21.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Poznaj trzy skuteczne metody: AOA, AOA Lead Ads i integracja z web to learn. Dowiedz się, co robić, gdy leady stają się drogie. Poznaj metody które zwiększają zainteresowanie zakupem Twoich usług i produktów. Dowiedz się wykorzystać darmowe materiały do budowanie wiarygodnosci i wypełniania górnej części lejka sprzedażowego. I na koniec pokażemy jak technologia może podgrzewać Twoich zainteresowanych aby podsycić ogień pożądania i zapragnęli kupić od Ciebie.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/11-1.png"
    },
    {
        "id": "12",
        "name": "Reklamy z AI – proste i skuteczne - zarabiaj więcej wydając mniej!",
        "date": "22.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Dowiedz się, jak łatwo wdrożyć AI w kampanie na Facebooku, Google i Instagramie. Dlaczego ci co ograniczają się do reklam a nie robię reklam postów na Facebooku niszczą swoją sprzedaż. Naucz się, jak automatyzować marketing lokalnej firmy, łącząc telefon, Facebook, Instagram i Google Ads.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/15.png"
    },
    {
        "id": "13",
        "name": "Zbieranie leadów to dopiero początek – czas na ich 'podgrzanie'!",
        "date": "23.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Poznaj wady tradycyjnych kampanii leadowych takich jak: zapis przez landing page, zbieranie leadów przez Messenger BOT`a, oraz kampanie Lead Ads. Dowiedz się, jak AI zmieni nastawienie lead z zainteresowanego na pragnącego kupić jeszcze przed kontaktem z twojej strony, dzięki automatycznemu 'ocieplaczowi'.",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/16.png"
    },
    {
        "id": "14",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "24.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 1",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/17.png"
    },
    {
        "id": "15",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "25.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 2",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/18.png"
    },
    {
        "id": "16",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "26.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 3",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/19.png"
    },
    {
        "id": "17",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "27.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 4",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/20.png"
    },
    {
        "id": "18",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "28.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 5",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/21.png"
    },
    {
        "id": "19",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "29.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 6",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/22.png"
    },
    {
        "id": "20",
        "name": "Wyzwanie 'Twoja pierwsza reklama'",
        "date": "30.11.2024",
        "timeStart": "20:00",
        "timeEnd": "21:00",
        "description": "Seria odcinków, która przeprowadzi Cię przez każdy krok – od stworzenia pierwszej reklamy aż do wielkiego finału w Black Friday! - Odcinek 7",
        "link": "https://www.youtube.com/@FastTonyesPolska",
        "image": "https://fasttony.com/wp-content/uploads/2024/11/23.png"
    }
]
